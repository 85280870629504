import React from 'react';
import { Canvas } from '@react-three/fiber';
import { Environment, useEnvironment, ScrollControls } from '@react-three/drei';

import { SceneMovement } from './SceneMovement';
import { FracturedCrystal } from './FracturedCrystal';
import { Main } from '../pages/main/Main';

// const Helper = () => {

//     const three = useThree();
//     three.gl.setSize(window.innerWidth / 1.5, window.innerHeight / 1.5, false);
// };

const Scene = () => {
    const envMap = useEnvironment({files: '/textures/boiler_room_1k.hdr'});
    
    return (
        <Canvas
            legacy={true} 
            camera={{fov: 12, rotation: [-0.10, 0, 0], position: [0, 8, 40]} }
        >
            {/* <Helper/> */}
            <ambientLight color='#ffffff' intensity={0.5}/>

            <Environment map={envMap}/>

            <ScrollControls pages={12} damping={0.4}>
                <Main/>
                <SceneMovement/>
                <FracturedCrystal/>
            </ScrollControls>
        </Canvas>
    );
};

export default Scene;
