import React, { useRef } from 'react';
import { useGLTF, useScroll } from '@react-three/drei';
import { useFrame } from 'react-three-fiber';

export const FlyCrystals = (props) => {
    const { nodes, materials } = useGLTF('models/etherium.gltf');
    const rightCrystalRef = useRef();
    const leftCrystalRef = useRef();

    const scroll = useScroll();
    const checkPoints = [0.06, 0.19, 0.32, 0.2, 0.28, 0.42];

    useFrame(() => {
        if (!rightCrystalRef.current) {
            return;
        }

        if(scroll.offset > checkPoints[0] && scroll.offset <= checkPoints[1]) {
            rightCrystalRef.current.position.x = -12 + scroll.offset * 40;
            rightCrystalRef.current.position.z = -12 + scroll.offset * 55;
        }

        if(scroll.offset > checkPoints[1] && scroll.offset <= checkPoints[2]) {
            rightCrystalRef.current.position.x = 3.16 - scroll.offset * 40;
            rightCrystalRef.current.position.z = 8.9 - scroll.offset * 55;
        }

        if(scroll.offset > checkPoints[0] && scroll.offset <= checkPoints[2]) {
            rightCrystalRef.current.rotation.y = 0.08 - scroll.offset * 40;
            rightCrystalRef.current.rotation.x = 0.08 - scroll.offset * 90;
        }

        if(scroll.offset > checkPoints[3] && scroll.offset <= checkPoints[4]) {
            leftCrystalRef.current.position.x = 18 - scroll.offset * 75;
            leftCrystalRef.current.position.z = 18 - scroll.offset * 60;
        }

        if(scroll.offset > checkPoints[4] && scroll.offset <= checkPoints[5]) {
            leftCrystalRef.current.position.x = -24 + scroll.offset * 75;
            leftCrystalRef.current.position.z = -15.6 + scroll.offset * 60;
        }

        if(scroll.offset > checkPoints[3] && scroll.offset <= checkPoints[5]) {
            leftCrystalRef.current.rotation.y = 0.08 - scroll.offset * 40;
            leftCrystalRef.current.rotation.x = 0.08 - scroll.offset * 30;
        }

    });

    return (
        <group {...props} dispose={null}>
            <mesh
                ref={rightCrystalRef} 
                receiveShadow 
                castShadow 
                geometry={nodes.Cube.geometry} 
                material={materials.Material}
                position={[-8.79, 0, -8.39]}
                scale={1.5}
            />
            <mesh
                ref={leftCrystalRef} 
                receiveShadow 
                castShadow 
                geometry={nodes.Cube.geometry} 
                material={materials.Material}
                position={[8, 0, 8]}
                scale={2}
            />
        </group>
    );
};

useGLTF.preload('models/etherium.gltf');