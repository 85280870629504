import React, { useState, useCallback, useMemo } from 'react';

import classes from './dropdown.module.scss';

export const Dropdown = () => {

    const dropItems = useMemo(() => {
        return [
            [
                {
                    id: 'dd1',
                    text: 'What is Rewarder App?',
                    subtext: `The Rewarder App serves as a decentralized finance (DeFi) 
                platform that specializes in providing customized structured financial 
                products to meet the unique risk-return preferences of both retail and 
                institutional investors. Our pioneering Tranching mechanism, the initial 
                addition to our expanding product lineup, facilitates diversified investment 
                opportunities across various markets by leveraging interest rate vaults`,
                },
                {
                    id: 'dd2',
                    text: 'Where is Rewarder App?',
                    subtext: `Founded in 2021, OKX is a Seychelles-registered cryptocurrency exchange 
                that adopts blockchain technology to build the next-generation financial ecosystem. 
                We provide the latest trading services for hundreds of diverse crypto assets.`,
                },
                {
                    id: 'dd3', 
                    text: 'What are Rewarder App services?', 
                    subtext: `The services provided by the Rewarder App introduce innovative investment 
                instruments that are derived from and connected to underlying on-chain or real-world 
                assets. Employing a combination of credit/risk assessment, liquidity management, 
                and maturity transformation techniques, these instruments are designed to fulfill 
                specific investment objectives.`,
                },
                {
                    id: 'dd4', 
                    text: 'How long can I stake?', 
                    subtext: `## You can stake your crypto assets for 1-36 months. You can choose to 
                unstake your assets manually or restake them when your assets is unlocked`,
                },
            ],
            [
                {
                    id: 'dd5', 
                    text: 'What do I need to enroll?', 
                    subtext: `You’re at least 18 years old and a US resident with a valid Social Security
                number. Our verification process may require some applicants to submit documentation 
                such as a state-issued ID/Drivers License, Social Security card and/or a utility bill 
                to confirm your identity. If that is required, we’ll let you know!`,
                },
                {
                    id: 'dd6', 
                    text: 'Does Rewarder App have a Token?', 
                    subtext: `We have not yet launched the token. We will keep the community updated about 
                any progress`,
                },
                {
                    id: 'dd7', 
                    text: 'What kind of offers will be enabled by Rewarder App?', 
                    subtext: `It is possible to test on our servers and our domain, we also have an open 
                exchanger available as a demo site so that you can get acquainted with all the functions 
                of the exchanger`,
                },
                {
                    id: 'dd8', 
                    text: 'Is there protection from DDoS attacks?', 
                    subtext: `We recommend using the CloudFlare.com service (we also provide free services for
                connecting this service)`,
                },
            ],
        ];
    }, []);

    const [openId, setOpenId] = useState('');

    const toggleDropdown = useCallback((e) => {
        (openId !== e.currentTarget.id) ? setOpenId(e.currentTarget.id) : setOpenId('');
        
    }, [openId]);

    const itemDropdown = useCallback(({id, text, subtext}) => {

        return <div
            key={id}
            id={id}
            className={`${classes.dropdown} ${openId === id ? classes.open : ''}`}
            onClick={e => toggleDropdown(e)}
        >
            <div className={classes.dropdown__title}>
                <h4>{text}</h4>
                <img src={'/images/arrow.svg'} alt='arrow' />
            </div>
            <p>{subtext}</p>
        </div>;
    }, [openId, toggleDropdown]);

    return (
        <>
            {
                dropItems.map((arr, index) => {
                    const dropd = arr.map((item) => {
                        const {id, text, subtext} = item;
                        return itemDropdown({id, text, subtext});
                    });
                    return <div key={index} className={classes.column}>
                        {dropd}
                    </div>;
                })
            }
            
        </>
    );
};