import React, { useRef, useContext, useState } from 'react';
import { Scroll, useScroll } from '@react-three/drei';
import { useFrame } from 'react-three-fiber';

import classes from './main.module.scss';

import Context from '../../components/Context';
import { Down } from '../down/Down';
import { TextSection } from './TextSection';

export const Main = () => {

    const [counter, setCounter] = useState(1);

    const sectionOneRef = useRef();
    const sectionTwoRef= useRef();
    const sectionThreeRef = useRef();
    const sectionFourRef = useRef();

    const horisontalRef = useRef();
    const afterHorisontalRef = useRef();
    const scrollLeftRef = useRef();
    const scroll = useScroll();

    const value = useContext(Context);

    value.changeSection = e => {
        switch(e.currentTarget.id) {
        case 'top' :
            scroll.el.scrollTo({top: 0});
            break;
        case 'center' :
            scroll.el.scrollTo({top: 5500});
            break;
        case 'bottom' :
        case 'contact' :
            scroll.el.scrollTo({top: 12000});
            break;
        default :
            break;
        }
    };

    useFrame(() => {

        sectionOneRef.current.style.opacity = 1 - scroll.range(0, 1 / 10);
        sectionTwoRef.current.style.opacity = scroll.curve(0.06, 0.1);
        sectionThreeRef.current.style.opacity = scroll.curve(0.14, 0.1);
        sectionFourRef.current.style.opacity = scroll.curve(0.22, 0.1);

        if(!horisontalRef.current && !scrollLeftRef.current && !afterHorisontalRef.current) {
            return;
        }

        if(scroll.offset > 0.342 && scroll.offset < 0.52) {
            setCounter(Math.floor(scroll.offset * 10) - 2);

            const positionY = 'translateY(' + (scroll.offset * 11 * window.innerHeight - window.innerHeight * 3.8) + 'px) translateZ(0)';
            horisontalRef.current.style.transform = positionY;
            afterHorisontalRef.current.style.transform = positionY;
            
            const positionX = 'translateX(-' + (scroll.offset * 8 * window.innerWidth - window.innerWidth * 3.15) + 'px) translateZ(0)';
            scrollLeftRef.current.style.transform = positionX;
        }
    });

    return(
        <Scroll html>
            <main className={classes.main}>
                <TextSection
                    instRef={sectionOneRef}
                    text='CRYPTO AI STAKING WEB3 INVESTMENTS'
                    subtext='Highly Favored Investment Opportunity for Individuals'
                />
                <TextSection
                    instRef={sectionTwoRef}
                    text='INNOVATIVE AI WEB3 PERFORMANCE'
                    subtext='We classify yield-bearing assets into different vaults based 
                        on varying levels of risk within our range of interest rate products'
                />
                <TextSection
                    instRef={sectionThreeRef}
                    stylePos={'left'}
                    text='FIXED REVENUE'
                    subtext='Choose a fixed return option to acquire a reliable and predictable 
                        yield source, making it an ideal choice for investors who prioritize stability above all else'
                />
                <TextSection
                    instRef={sectionFourRef}
                    stylePos={'right'}
                    text='VARIABLE REVENUE'
                    subtext='Through leveraged exposure to the performance of the underlying assets, our variable 
                        return option provides enhanced returns, offering an opportunity for amplified growth'
                />
                <section ref={horisontalRef} className={classes.main__sectionhorizontal}>
                    <div className={classes.counter}>
                        {`${counter} / 3`}
                        <progress className={classes.counter__progress} value={counter} max={3}></progress>
                    </div>
                    <div ref={scrollLeftRef} className={classes.scrollcontainer}>
                        <div className={classes.scrollcontainer__hcell}>
                            <h2 className={classes.scrollcontainer__hcell_texthorizontal}>
                                COMPARE
                            </h2>
                            <h3 className={classes.scrollcontainer__hcell_subtexthorizontal}>
                                Find out your own interest-rate offers
                            </h3>
                        </div>
                        <div className={classes.scrollcontainer__hcell}>
                            <h2 className={classes.scrollcontainer__hcell_texthorizontal}>
                                DEPOSIT
                            </h2>
                            <h3 className={classes.scrollcontainer__hcell_subtexthorizontal}>
                                Deploy assets into a vault that fits your needs
                            </h3>
                        </div>
                        <div className={classes.scrollcontainer__hcell}>
                            <h2 className={classes.scrollcontainer__hcell_texthorizontal}>
                                GAIN
                            </h2>
                            <h3 className={classes.scrollcontainer__hcell_subtexthorizontal}>
                                Mind your own business and let us earn you yield
                            </h3>
                        </div>
                    </div>
                </section>
            </main>
            <div ref={afterHorisontalRef} className={classes.main}>
                <Down />
            </div>
        </Scroll>
    );
};