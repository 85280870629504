import React, { useRef } from 'react';
import { useGLTF, useAnimations, useScroll } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';

export const FracturedCrystal = (props) => {
    const { nodes, materials, animations } = useGLTF('models/etherium_fract.gltf');
    const groupRef = useRef();
    const { actions } = useAnimations(animations, groupRef);
    const scroll = useScroll();

    materials.Material.roughness = 0.1;
    materials.Material.metalness = 0.9;
    materials.Material.transparent = false;
    // materials.Material.opacity = 0.8;

    useFrame(() => {
        if(!actions.KeyAction) {
            return;
        }

        if(scroll.offset > 0.3 && scroll.offset < 0.7) {
            groupRef.current.position.z = -(scroll.offset * 350) + 160;
        }

        if(scroll.offset > 0.4 && scroll.offset < 0.6) {
            actions.KeyAction.play();
            actions.KeyAction.getMixer().setTime((scroll.offset - 0.6) * 5);
        }

        if(scroll.offset >= 0.6) {
            actions.KeyAction.getMixer().setTime(1.999);
        }

        if (scroll.offset < 0.4) {
            actions.KeyAction.stop();
        }
        
    });

    return (
        <group 
            {...props} 
            ref={groupRef}
            scale={8}
            rotation={[0, 1.5, 0]}
            position={[0, -2, 50]}
            dispose={null}
        >
            <mesh
                position={[0, 0, 0]}
                name="Cube"
                castShadow
                receiveShadow
                geometry={nodes.Cube.geometry}
                material={materials.Material}
                morphTargetDictionary={nodes.Cube.morphTargetDictionary}
                morphTargetInfluences={nodes.Cube.morphTargetInfluences}
            />
        </group>
    );
};

useGLTF.preload('models/etherium_fract.gltf');