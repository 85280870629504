import React, { useRef } from 'react';
import { useScroll } from '@react-three/drei';
import { useFrame } from 'react-three-fiber';

import classes from './down.module.scss';
import classesApp from '../../styles/app.module.scss';

import { Dropdown } from './Dropdown';
import { Backed } from './Backed';
import { Contacts } from './Contacts';
import { Footer } from './Footer';

export const Down = () => {

    const scroll = useScroll();

    const handleJoin = () => {
        scroll.el.scrollTo({top: 12000});
    };

    const handleTop = () => {
        scroll.el.scrollTo({top: 0});
    };

    const sectionRef = useRef();

    useFrame(() => {
        sectionRef.current.style.opacity = scroll.curve(0.52, 0.15);
    });

    return(
        <>
            <section className={classes.security}>
                <div ref={sectionRef} className={classes.center}>
                    <h2 className={classes.center_text}>
                        PERSONALIZE YOUR OWN FINANCIAL CONTRACTS
                    </h2>
                    <h3 className={classes.center_subtext}>
                        Effortlessly generate your own Interest Rate Products using our smart contract Factory
                    </h3>
                    <button 
                        className={`${classesApp.button} ${classesApp.buttonvariant}`}
                        onClick={handleJoin}
                    >
                        JOIN WAITLIST
                    </button>
                    {/* <h2 className={classes.center_text}>
                        SECURITY
                    </h2>
                    <h3 className={classes.center_subtext}>
                        At Rewarder, prioritizing security is paramount. 
                        Our smart contracts undergo rigorous internal testing and thorough audits conducted by respected industry leaders
                    </h3> */}
                </div>
            </section>
            <div className={classes.under}>
                <section className={classes.under__section}>
                    <h2 className={classes.under__section_text}>
                        BACKED BY
                    </h2>
                    <Backed/>
                </section>
                <section className={classes.under__sectionfaq}>
                    <h2 className={classes.under__section_text}>
                        F.A.Q.
                    </h2>
                    <div className={classes.containerfaq}>
                        <div className={classes.faq}>
                            <Dropdown/>
                        </div>
                    </div>
                </section>
            </div>
            <footer className={classes.down}>
                <section className={classes.down__section}>
                    <h2 className={classes.down__section_text}>
                        NEWSLETTER SIGNUP
                    </h2>
                    <Contacts/>
                    <div className={classes.down__footer}>
                        <h6>Rewarder 2023</h6>
                        <Footer/>
                    </div>
                    <button 
                        className={classes.down__button}
                        onClick={handleTop}
                    >
                        Back To Top
                    </button>
                </section>
            </footer>
        </>
    );
};