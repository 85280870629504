import Scene from './features/components/Scene';
import { Header } from './features/pages/header/Header';

import classes from  './features/styles/app.module.scss';

import Context from './features/components/Context';

function App() {

    const changeSection = (e) => {};

    const value = {
        changeSection,
    };

    return (
        <div className={classes.main}>
            <Context.Provider value={value}>
                <Header/>
                <Scene/>
            </Context.Provider>
        </div>
    );
}

export default App;
