export const outsideLigthPros = {
    color: '#11b85b',
    position: [0, 3, 0],
    intensity: 5,
    distance: 35,
    penumbra: 1.7,
};

export const insideLigthPros = {
    color: '#11b85b',
    position: [0, 4, 0],
    intensity: 6,
    distance: 18,
    penumbra: 5,
};
