import React, {useState, useContext} from 'react';

import classes from './header.module.scss';
import classesApp from '../../styles/app.module.scss';

import Context from '../../components/Context';
import { Footer } from '../down/Footer';

export const Header = () => {

    const [isOpen, setIsOpen] = useState(false);

    const value = useContext(Context);

    const menuHandle = () => {
        setIsOpen(!isOpen);
    };

    const handleJoin = (e) => {
        value.changeSection(e);
        setIsOpen(false);
    };

    return(
        <div className={`${classes.pos} ${isOpen ? classes.open : ''}`}>
            <header className={classes.header}>
                <h1 className={classes.header__title}>Rewarder</h1>
                <nav className={classes.header__nav}>
                    <button 
                        className={classes.header__nav_bsmall}
                        onClick={menuHandle}
                    />
                    <button
                        id={'contact'}
                        className={`${classesApp.button} ${isOpen ? classes.scalebutton : ''}`}
                        onClick={handleJoin}
                    >
                    JOIN WAITLIST
                    </button>
                </nav>
                <div className={`${classes.menu} ${isOpen ? classes.open : ''}`}>
                    <div className={classes.menu__container}>
                        <h5 
                            id={'top'} 
                            className={classes.menu__container_text} 
                            onClick={handleJoin}
                        >RESOURCES</h5>
                        <hr />
                        <h5 
                            id={'center'} 
                            className={classes.menu__container_text} 
                            onClick={handleJoin}
                        >CUSTOMIZE</h5>
                        <hr />
                        <h5 
                            id={'bottom'}
                            className={classes.menu__container_text} 
                            onClick={handleJoin}
                        >NEWSLETTER</h5>
                        <hr />
                        <Footer/>
                    </div>
                </div>
            </header>
        </div>
    );
};