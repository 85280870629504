import React, { useMemo } from 'react';

import classes from './backed.module.scss';

export const Backed = () => {

    const imgNames = useMemo(() => {
        return [
            'btc', 'eth', 'ltc', 'bnb', 'sol',
            'usdt', 'shib', 'avax', 'doge', 'dot',
            'icp', 'link', 'matic', 'trx', 'xmr',
        ];
    }, []);

    const imagesPerWidth = useMemo(() => {
        return window.innerWidth < 450 ? imgNames.slice(0, 8) : imgNames;
    }, [imgNames]);

    return (
        <div className={classes.backed}>
            {
                imagesPerWidth.map((name, index) => {
                    return <img key={index} className={classes.img} src={`/images/${name}.svg`} alt='coin logo' />;
                })
            }
        </div>
    );
};