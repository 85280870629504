import React from 'react';

import classes from './footer.module.scss';

export const Footer = () => {

    const productLinks = [
        'Docs', 
        'Litepaper', 
        'Proposals'];

    const socialLinks = [
        'Discord',
        'Twitter',
        'Telegram',
        'LinkedIn',
        'Medium'];

    return (
        <div className={classes.footer}>
            <div className={classes.footer__container}>
                <h6>Product</h6>
                <ul>
                    {
                        productLinks.map((item, index) => {
                            return <li key={'Pr' + index}>
                                <img className={classes.img} src={'/images/arrow.svg'} alt='arrow' />
                                {item}
                            </li>;
                        })
                    }
                </ul>
            </div>
            <div className={classes.footer__container}>
                <h6>Social</h6>
                <ul>
                    {
                        socialLinks.map((item, index) => {
                            return <li key={'So' + index}>
                                <img className={classes.img} src={'/images/arrow.svg'} alt='arrow' />
                                {item}
                            </li>;
                        })
                    }
                </ul>
            </div>
        </div>
    );
};