import React, { useRef } from 'react';
import { useScroll } from '@react-three/drei';
import { useFrame } from 'react-three-fiber';

import { RingLights } from './RingLights';
import { RingCrystals } from './RingCrystals';
import { CloudCrystals } from './CloudCrystals';
import { FlyCrystals } from './FlyCrystals';

export const SceneMovement = () => {
    
    const scroll = useScroll();
    const containerRef = useRef();
    const objectRef = useRef();

    const scrollMultipli = 15;
    const checkPoints = [Math.PI / 2, 3.5, 0.04, 0.18, 0.68, 0.79, 0.84];

    useFrame((state) => {
        if (!containerRef.current) {
            return;
        }

        if(scrollMultipli * scroll.offset < checkPoints[0]) {
            containerRef.current.rotation.y = -(scrollMultipli * scroll.offset);
        }

        if(scrollMultipli * scroll.offset >= checkPoints[0] && scrollMultipli * scroll.offset < checkPoints[1]) {
            containerRef.current.rotation.x = (scrollMultipli * scroll.offset) - checkPoints[0];
        }

        if(scroll.offset >= checkPoints[2] && scroll.offset < checkPoints[3]) {
            containerRef.current.position.z = scroll.offset * 100 - 34;
        }

        if(scroll.offset > checkPoints[3] && scroll.offset <= checkPoints[4]) {
            containerRef.current.position.z = -(scroll.offset * 200) + 19;
        }

        objectRef.current.rotation.x = -(state.mouse.y * 0.03);
        objectRef.current.rotation.y = state.mouse.x * 0.02;

        if(scroll.offset > checkPoints[6] && window.innerWidth > 1950) {
            scroll.offset = checkPoints[6];
        }
    });
    
    return (
        <group ref={containerRef} position={[0, -3, -30]}>
            <object3D ref={objectRef}>
                <FlyCrystals/>
                <RingLights/>
                <RingCrystals/>
                <CloudCrystals/>
            </object3D>
        </group>
    );
};
