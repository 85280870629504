import React, { useEffect, useRef } from 'react';
import { useGLTF } from '@react-three/drei';

export const RingCrystals = (props) => {
    const { nodes, materials } = useGLTF('models/etherium.gltf');
    const count = 150;
    const dummyRef = useRef();
    const meshRef = useRef();

    materials.Material.roughness = 0.3;
    materials.Material.metalness = 0.9;
    materials.Material.transparent = true;
    materials.Material.opacity = 0.6;
    
    useEffect(() => {
        let currentAngle = 0;
        let radius = 10;

        for(let i = 0; i < count; i++) {
            currentAngle += 1.5;
            
            radius += 0.02;
            dummyRef.current.position.x = Math.cos(currentAngle) * (radius + Math.random());
            dummyRef.current.position.y = Math.random() * 3;
            dummyRef.current.position.z = Math.sin(currentAngle) * (radius + Math.random());

            dummyRef.current.rotation.y = Math.random() * 2 * Math.PI;
            dummyRef.current.rotation.z = 0.2;

            dummyRef.current.scale.x = dummyRef.current.scale.y = dummyRef.current.scale.z = Math.random() + 0.6;

            dummyRef.current.updateMatrix();

            meshRef.current.setMatrixAt(i, dummyRef.current.matrix);
        }
    }, []);

    return (
        <group {...props} dispose={null}>
            <object3D ref={dummyRef} />
            <instancedMesh ref={meshRef} receiveShadow castShadow args={[nodes.Cube.geometry, materials.Material, count]}/>
        </group>
    );
};

useGLTF.preload('models/etherium.gltf');