import React from 'react';

import classes from './main.module.scss';

export const TextSection = ({instRef, text, subtext, stylePos='center'}) => {
    
    return (
        <section ref={instRef} className={classes.main__section}>
            <div className={classes[`${stylePos}`]}>
                <h2 className={classes[`${stylePos}_text`]}>
                    {text}
                </h2>
                <h3 className={classes[`${stylePos}_subtext`]}>
                    {subtext}
                </h3>
            </div>
        </section>
    );
};