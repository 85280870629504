import React, { useCallback, useMemo, useRef } from 'react';
import { useFrame } from 'react-three-fiber';

import { outsideLigthPros, insideLigthPros} from '../props/lightProps';

export const RingLights = () => {
    const lightOutsideCount = 8;
    const lightInsideCount = 5;
    const lightRadius = [22, 7.3];
    const lightsOutsideRef = useRef([<spotLight/>]);
    const lightsInsideRef = useRef([<spotLight/>]);
    let lightAngle = 0;
    
    const createLigts = useCallback((
        count, 
        lightRef, 
        ...attrs
    ) => {
        const lights = [];
        for(let i = 0; i < count; i++) {
            const light = <spotLight
                ref={el => lightRef.current[i] = el}
                key={'lto' + i}
                {...attrs[0]}
            />;
            lights.push(light);
        }

        return lights;
    }, []);

    const lightsOutside = useMemo(() => {
        return createLigts(
            lightOutsideCount,
            lightsOutsideRef,
            {...outsideLigthPros}
        );
        
    }, [createLigts]);

    const lightsInside = useMemo(() => {
        return createLigts(
            lightInsideCount,
            lightsInsideRef,
            {...insideLigthPros}
        );
        
    }, [createLigts]);

    const lightsMove = (light, count, lightRadius) => {
        light.position.x = Math.cos(lightAngle + count) * lightRadius;
        light.position.z = Math.sin(lightAngle + count) * lightRadius;
    };

    useFrame(() => {
        lightAngle += 0.002;

        lightsOutsideRef.current && lightsOutsideRef.current.forEach((light, i) => {
            lightsMove(light, i, lightRadius[0]);
        });

        lightsInsideRef.current && lightsInsideRef.current.forEach((light, i) => {
            lightsMove(light, i, lightRadius[1]);
        });
    });

    return (
        <>
            {lightsOutside.map((light) => {
                return light;
            })}

            {lightsInside.map((light) => {
                return light;
            })}
        </>
    );
};