import React from 'react';

import classes from './contacts.module.scss';
import classesApp from '../../styles/app.module.scss';

export const Contacts = () => {

    return (
        <div className={classes.contacts}>
            <form className={classes.contacts__form}>
                <input
                    className={classes.contacts__form_input}
                    type='text' 
                    name='name' 
                    placeholder='Your Name' 
                    required
                />
                <input
                    className={classes.contacts__form_input}
                    type='email' 
                    name='email' 
                    placeholder='E-mail address' 
                    required
                />
                <button style={{marginTop: '30px', marginBottom: '30px'}} type='submit' className={`${classesApp.button} ${classesApp.buttonvariant}`}>JOIN</button>
            </form>
        </div>
    );
};